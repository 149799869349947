.prediction-charts, .glucose-charts {
  margin-top: 4em;
}

.trend-note {
    display: block;
    font-size: 12px;
    margin-top: 1em;
    width: 400px;
}

.prediction-charts div {
    margin-top: 2em;
}

.glucose-charts > div {
  margin: 2em auto 0 auto;
}

/* .chart-density, .chart-hours { */
/*   display: inline-block; */
/*   width: 50%; */
/* } */

.charts {
  width: calc(100% - 50px);
  margin: 0 0 0 50px;
  display: flex;
}

.chart-container {
  width: 80%;
  margin: 0 auto;
}

.chart-picker {
  font-size: 2.33em;
  font-weight: 700;
  height: 150px;
}

.chart-picker img {
    width: 30px;
}

.chart-picker button {
  background-color: var(--accent-color);
  padding: 15px;
  display: flex;
  color: white;
}

.chart-picker button.active{
  background-color: var(--accent-color-b);
}
