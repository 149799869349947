.container {
  display: flex;
  min-height: 100vh;
}

.navbar {
  width: 50px;
  background-color: var(--accent-color);
}

.login, .callback {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login button {
  border: 3px solid black;
  padding: 5px;
  font-weight: 700;
}
