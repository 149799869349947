.navbar {
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}


.navbar .logo {
  margin-left: -52px;
  width: 150px;
  transform: rotate(-90deg);
}


.navbar .logout {
  position: absolute;
  left: 10px;
  top: 15px;
  width: 30px;
}
