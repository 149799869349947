.pred-checkbox {
    margin-right: 2em;
}

.vitals {
  display: flex;
  justify-content: space-around;
}


.vitals-component .icon polygon {
  /* fill: red; */
  /* width: 30%; */
  /* height: 100px; */

}

.vitals-component .title {
  text-transform: uppercase;
  text-align: center;
  font-size: .8em;
  margin-bottom: 1em;
}

.vitals-component .icon {
  display: inline-block;
}

.vitals-component .vital {
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.vitals-component .value {
  font-size: 1.75em;
  font-weight: 700;
}

.vitals-component .value span{
  display: block;
  text-align: center;
}

/* .vitals-component .icon { */
/*   padding: 10px; */
/* } */
