@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,700);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

button {
  cursor: pointer;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

:root {
  /* --main-bg-color: rgb(225, 220, 225); */
  --main-bg-color: #FFFFF;
  --accent-color: rgba(129,61,138,.8);
  --accent-color-b: #1abc9c;
  --font: "Raleway", sans-serif;
}


body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFF;
  background-color: var(--main-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
}

.navbar {
  width: 50px;
  background-color: var(--accent-color);
}

.login, .callback {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.login button {
  border: 3px solid black;
  padding: 5px;
  font-weight: 700;
}



.chart-filters {
  width: 90%;
  margin: 2em auto 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}


input {
  font-family: -apple-system, BlinkMacSystemFont, "Raleway", sans-serif;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid black;
  font-weight: 700;
  font-size: 32px;
}

input:focus {
  outline: none !important;
}

input::-webkit-inner-spin-button,
input ::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.form-patient input {
  width: 220px;
}

.form-days input {
  width: 70px;
  text-align: center;
}

.form-radio {
    margin-top: 1em;
}

.form-radio input {
  border: none;
  font-weight: inherit;
  display: inline-block;
  position: relative;
  background-color: #f1f1f1;
  color: #666;
  top: 4px;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 0px;
  cursor: pointer;
  margin: 0 15px 0 0;
  outline: none;
}

.form-radio label {
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
}

.pred-checkbox {
    margin-right: 2em;
}

.vitals {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}


.vitals-component .icon polygon {
  /* fill: red; */
  /* width: 30%; */
  /* height: 100px; */

}

.vitals-component .title {
  text-transform: uppercase;
  text-align: center;
  font-size: .8em;
  margin-bottom: 1em;
}

.vitals-component .icon {
  display: inline-block;
}

.vitals-component .vital {
  margin-top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.vitals-component .value {
  font-size: 1.75em;
  font-weight: 700;
}

.vitals-component .value span{
  display: block;
  text-align: center;
}

/* .vitals-component .icon { */
/*   padding: 10px; */
/* } */

.prediction-charts, .glucose-charts {
  margin-top: 4em;
}

.trend-note {
    display: block;
    font-size: 12px;
    margin-top: 1em;
    width: 400px;
}

.prediction-charts div {
    margin-top: 2em;
}

.glucose-charts > div {
  margin: 2em auto 0 auto;
}

/* .chart-density, .chart-hours { */
/*   display: inline-block; */
/*   width: 50%; */
/* } */

.charts {
  width: calc(100% - 50px);
  margin: 0 0 0 50px;
  display: -webkit-flex;
  display: flex;
}

.chart-container {
  width: 80%;
  margin: 0 auto;
}

.chart-picker {
  font-size: 2.33em;
  font-weight: 700;
  height: 150px;
}

.chart-picker img {
    width: 30px;
}

.chart-picker button {
  background-color: var(--accent-color);
  padding: 15px;
  display: -webkit-flex;
  display: flex;
  color: white;
}

.chart-picker button.active{
  background-color: var(--accent-color-b);
}

.navbar {
  position: fixed;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}


.navbar .logo {
  margin-left: -52px;
  width: 150px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}


.navbar .logout {
  position: absolute;
  left: 10px;
  top: 15px;
  width: 30px;
}

