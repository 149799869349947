
.chart-filters {
  width: 90%;
  margin: 2em auto 0 auto;
  display: flex;
  justify-content: space-between;
}


input {
  font-family: -apple-system, BlinkMacSystemFont, "Raleway", sans-serif;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid black;
  font-weight: 700;
  font-size: 32px;
}

input:focus {
  outline: none !important;
}

input::-webkit-inner-spin-button,
input ::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.form-patient input {
  width: 220px;
}

.form-days input {
  width: 70px;
  text-align: center;
}

.form-radio {
    margin-top: 1em;
}

.form-radio input {
  border: none;
  font-weight: inherit;
  display: inline-block;
  position: relative;
  background-color: #f1f1f1;
  color: #666;
  top: 4px;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 0px;
  cursor: pointer;
  margin: 0 15px 0 0;
  outline: none;
}

.form-radio label {
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
}
